import React from "react";
import CommonFormikForm from "../../../utils/form";
import useTranslation from "../../../utils/hooks/use-translation";
import {
  mobileNoValidationSchema,
  referCodeValidationSchema,
} from "../../../utils/validations";
import { sendReferralAPI, verifyRefCodeAPI } from "../../../services";
import useLoader from "../../../utils/hooks/use-loader";
import {
  getCDSPixelId,
  getlanguageId,
  handleErrorMessages,
  isClaimFormWindowOpen,
} from "../../../utils/helpers";
import AppModal from "../../modals";
import usePopup from "../../../utils/hooks/use-popup";
import useSuccessHandler from "../../../utils/hooks/use-success-model";
import { isTamil } from "../../../utils/helpers";

const ReferAFriendForm = ({
  getReferralList,
  type,
  show = false,
  onClose = {},
}) => {
  const { handleSuccess } = useSuccessHandler();
  const { t, currentLanguage } = useTranslation();
  const { toggleLoader } = useLoader();
  const { openSuccessPopup } = usePopup();

  console.log("translation", t);
  const initialValues = {
    mobile: "",
  };

  const initialValues2 = {
    code: "",
  };

  //handle submit button action
  const handleSubmit = async (values, { setFieldError, resetForm }) => {
    // Handle form submission
    console.log(values, "values");
    toggleLoader(true);
    if (type === "refer_code") {
      verifyRefer(values, setFieldError, resetForm);
    } else {
      sendRefer(values, setFieldError, resetForm);
    }
  };

  const rederReferSuccessModel = (data) => {
    let contentType = {
      image: "/assets/img/modal/right-icon.svg",
      heading: `${t.common.thank_you}!`,
      subHeading: `${t.auth.referal_invite_subHeading}`,
      code: `“${data.code}”`,
      description: t.model_messages.refer_success_description,
    };
    openSuccessPopup(true, contentType);
  };

  //send refer api call
  const sendRefer = async (values, setFieldError, resetForm) => {
    try {
      const hfSessionKey = await getCDSPixelId();
      let reqData = {
        referTo: values.mobile,
        hfSession: hfSessionKey || "",
        languageid: getlanguageId(currentLanguage),
      };
      const res = await sendReferralAPI(reqData);
      if (res.data && res.data.succeeded) {
        let resData = {
          code: res.data && res.data.data,
          message: res.data && res.data.message,
          coinsCount: 0,
        };
        //GA event added for successfully refer number
        if (isTamil()) {
          window.dataLayer.push({ event: "CSTamil24_ReferFriend_Submit" });
        } else {
          window.dataLayer.push({ event: "CSBharat24_ReferFriend_Submit" });
        }
        resetForm();
        getReferralList();
        onClose();
        rederReferSuccessModel(resData);
        console.log("res.data.succeeded", resData);
      } else {
        console.log("res.data", res.data.message);
        handleErrorMessages(res, setFieldError, "mobile");
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  //verify refer code api call
  const verifyRefer = async (values, setFieldError, resetForm) => {
    try {
      const hfSessionKey = await getCDSPixelId();
      const langId = getlanguageId(currentLanguage);
      let reqData = {
        refCode: values.code,
        hfSession: hfSessionKey || "",
        languageId: langId,
      };
      const res = await verifyRefCodeAPI(reqData);
      if (res.data && res.data.succeeded) {
        //GA event added for successfully refer number
        if (isTamil()) {
          window.dataLayer.push({ event: "CSTamil24_HaveInviteCode_Submit" });
        } else {
          window.dataLayer.push({ event: "CSBharat24_HaveInviteCode_Submit" });
        }
        resetForm();
        onClose();
        let resData = res.data && res.data.data;
        let claimableRewardId =
          resData?.rewardId !== 1 && resData?.canClaim === true
            ? resData?.rewardId
            : null;
        // console.log(claimableRewardId,"claimableRewardId","refer_code")
        // claimableRewardId && handleCLaimWindow(claimableRewardId, langId)
        // handleSuccess(resData, 'refer_code');
        // if (claimableRewardId) {
        //   isClaimFormWindowOpen(claimableRewardId, langId).then((result) => {
        //     handleSuccess(resData, "refer_code", result);
        //   });
        // } else {
          resData?.canClaim === false && handleSuccess(resData, "refer_code");
        // }
      } else {
        let message = res.data && res.data.message;
        setFieldError("code", message);
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  const renderInput = [
    {
      type: "text",
      name: "mobile",
      placeHolder: t.referal.enter_friend_mobile_number,
      inputType: "number",
      //formGroupClass: "form-refer m-0"
    },
  ];

  const renderInput2 = [
    {
      type: "text",
      name: "code",
      placeHolder: "XXXXXX",
      inputType: "text",
    },
  ];

  let referCodeValidation = referCodeValidationSchema(t);
  let mobileNoValidation = mobileNoValidationSchema(t);

  return (
    <>
      {type === "refer_code" ? (
        <AppModal
          show={show}
          onClose={onClose}
          bodyClass="px-4 py-9 modal-response modal-unique-code text-center"
        >
          <h5 className="modal-heading pb-4 pb-md-5 text-center">
            {t.common.enter_referal_code}
          </h5>
          <div className="row justify-content-center">
            <div className="col-sm-10">
              <CommonFormikForm
                initialValues={initialValues2}
                onSubmit={handleSubmit}
                renderInputs={renderInput2}
                ButtonText="Submit"
                validationSchema={referCodeValidation}
                errorClass={"position-relative mb-n5"}
              />
            </div>
          </div>
        </AppModal>
      ) : type === "refer_number" ? (
        <AppModal
          show={show}
          onClose={onClose}
          headingClass="pt-3 pb-2 pl-4 p-md-5"
          bodyClass="px-4 modal-response modal-unique-code refer-popup"
        >
          <h5 className="modal-heading pb-2 pb-md-5 mt-2 mt-md-4 text-left">
            {t.referal.get_your_friend_grooving}
          </h5>
          <span className="text-muted text-left">
            {" "}
            {t.referal.invite_your_friend_to_join}
          </span>
          <div className="row justify-content-center mt-4">
            <div className="col-sm-10">
              <CommonFormikForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                renderInputs={renderInput}
                submitBtnClass={`btn-refer`}
                formType="refer_Now"
                ButtonText={t.referal.refer_Now}
                validationSchema={mobileNoValidation}
                errorClass={"position-relative mb-n5"}
              />
            </div>
          </div>
        </AppModal>
      ) : (
        <CommonFormikForm
          formClass={"d-flex align-items-center"}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          renderInputs={renderInput}
          ButtonText="Refer Now"
          submitBtnClass={`btn-refer`}
          validationSchema={mobileNoValidation}
        />
      )}
    </>
  );
};

export default ReferAFriendForm;
