import React from "react";
import { Nav, NavDropdown, Image } from "react-bootstrap";
import useAuth from "../../utils/hooks/use-auth";
import {
  CounterFloodLight,
  manageKaraokePath,
} from "../../utils/helpers";
import gluedin from "gluedin";
import { useDispatch } from "react-redux";
import { setAfterLoginAction } from "../../store/slices/common-slice";
import usePopup from "../../utils/hooks/use-popup";
import { isTamil } from "../../utils/helpers";
import { gluedinLogin } from "../../services";
import useTranslation from "../../utils/hooks/use-translation";

const NavLinks = (props) => {
  const { changeLanguage } = useTranslation();
  const dispatch = useDispatch();
  const { openReferFriendPopup, openContactUsPopup } = usePopup();

  const { data, navigateTo } = props;
  const { isLoggedIn, userDetails } = useAuth();
  let siteType = isTamil() ? "tamil" : "bharat";

  if (!data || !Array.isArray(data)) {
    console.error("Invalid or missing data array.");
    return null;
  }

  const gluedinLogout = () => {
    sessionStorage.removeItem("guestToken");
    sessionStorage.removeItem("community-closed");
    sessionStorage.removeItem("uniqueToken");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("hdkjfsdhfkhdskfhkdshfkdsgcvbjhsdbvdfkjh");
  };

  const handleGluedinLogin = () => {
    let formData = {
      email: userDetails && `${userDetails.id}@gmail.com`,
      password: userDetails && userDetails.id,
    };
    gluedinLogin(formData);
  };

  const handleTamilRedirection = () => {
    changeLanguage("en");
    console.log("called tamil redirection");
    // dispatch(logoutUser());
    // localStorage.clear();
    gluedinLogout();
    window.location.reload();
    localStorage.setItem("siteType", "tamil");
    let gluedInSDKInitilize = new gluedin.GluedInSDKInitilize();
    gluedInSDKInitilize.initialize({
      apiKey: process.env.REACT_APP_API_KEY_TAMIL,
      secretKey: process.env.REACT_APP_SECRET_KEY_TAMIL,
    });
    handleGluedinLogin();
  };

  const handleBharatRedirection = () => {
    changeLanguage("en");
    // dispatch(logoutUser());
    // localStorage.clear();
    gluedinLogout();
    window.location.reload();
    localStorage.setItem("siteType", "bharat");
    let gluedInSDKInitilize = new gluedin.GluedInSDKInitilize();
    gluedInSDKInitilize.initialize({
      apiKey: process.env.REACT_APP_API_KEY_BHARAT,
      secretKey: process.env.REACT_APP_SECRET_KEY_BHARAT,
    });
    handleGluedinLogin();
  };
  console.log(data, "datadatadata");
  return (
    <>
      <Nav className="me-auto mt-5" as={"ul"}>
        {data.map((el, i) =>
          el && !el.subMenu ? (
            <Nav.Item as="li" key={i}>
              {el.id === 1 && el.type === "contact_us" ? (
                <Nav.Link
                  onClick={() => {
                    openContactUsPopup();
                    CounterFloodLight([
                      "DC-12665261/coca-0/cocac01s+unique",
                      "DC-12665261/coca-0/cocac02l+unique",
                    ]);
                    navigateTo();
                  }}
                >
                  <Image className="icon" src={el.image} alt={el.title} />
                  <span>{el.title}</span>
                </Nav.Link>
              ) : (
                <Nav.Link
                  href={
                    el.url
                  }
                  onClick={() => {
                    navigateTo();
                    if (el.id === 2) {
                      CounterFloodLight([
                        "DC-12665261/coca-0/cocac01z+unique",
                        "DC-12665261/coca-0/cocac02s+unique",
                      ]);
                    }
                    if (el.id === 3) {
                      CounterFloodLight([
                        "DC-12665261/coca-0/cocac02e+unique",
                        "DC-12665261/coca-0/cocac038+unique",
                      ]);
                    }
                    if (el.id === 4) {
                      CounterFloodLight([
                        "DC-12665261/coca-0/cocac02-+unique",
                        "DC-12665261/coca-0/cocac033+unique",
                      ]);
                    }
                  }}
                >
                  {/* {isTamil() &&
                  el.title === "Participate & Win" &&
                  <Image className="icon" src={el.image} alt={el.title} />} */}
                  <Image className="icon" src={el.image} alt={el.title} />
                  <span>{el.title}</span>
                </Nav.Link>
              )}
            </Nav.Item>
          ) : (
            <NavDropdown
              as="li"
              key={i}
              show={true}
              className="d-block d-md-none"
              title={
                <>
                  <Image className="icon" src={el.image} alt={el.title} />
                  <span>{el.title}</span>
                </>
              }
            >
              {el.subMenu?.map((item, j) => (
                <NavDropdown.Item
                  key={j}
                  href={
                    (item.id === 13 && !isLoggedIn) ||
                    (item.id === 10 && !isLoggedIn)
                      ? `${siteType}/login`
                      : item.url
                  }
                  // {
                  //   (el.id === 13 && !isLoggedIn) ||
                  //     (el.id === 10 && !isLoggedIn)
                  //     ? `${siteType}/login`
                  //     : el.url || '#'
                  // }
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: item.title }}
                    onClick={() => {
                      if (item.id === 13) {
                        if (isLoggedIn) {
                          openReferFriendPopup();
                          CounterFloodLight([
                            "DC-12665261/coca-0/cocac02b+unique",
                            "DC-12665261/coca-0/cocac035+unique",
                          ]);
                        } else {
                          console.log("item.id &&", item.id);
                          dispatch(
                            setAfterLoginAction({
                              action: openReferFriendPopup,
                              redirectPath: "",
                            })
                          );
                        }
                      }
                      if (item.id === 10 && !isLoggedIn) {
                        console.log("item.id", item.id);
                        dispatch(
                          setAfterLoginAction({
                            action: "",
                            redirectPath: manageKaraokePath(),
                          })
                        );
                        CounterFloodLight([
                          "DC-12665261/coca-0/cocac021+unique",
                          "DC-12665261/coca-0/cocac02w+unique",
                        ]);
                      }
                      if (item.id === 6) {
                        handleTamilRedirection();
                      } else if (item.id === 7) {
                        handleBharatRedirection();
                      }
                      navigateTo();
                    }}
                  ></span>
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          )
        )}
      </Nav>
    </>
  );
};

export default NavLinks;
