import React from 'react';
import AppModal from '../../modals';
import CommonFormikForm from '../../../utils/form';
import { uniqueCodeValidationSchema } from '../../../utils/validations';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { mixCodeApi } from '../../../services';
import useLoader from '../../../utils/hooks/use-loader';
import useTranslation from '../../../utils/hooks/use-translation';
import { getModelType } from '../../../utils/constants';
import usePopup from '../../../utils/hooks/use-popup';
import useSuccessHandler from '../../../utils/hooks/use-success-model';
import { getCDSPixelId, getlanguageId, isClaimFormWindowOpen, isTamil } from '../../../utils/helpers';
import {
  handleInputLimitedLength,
  CounterFloodLight,
} from '../../../utils/helpers';
import { getUserDetails, getWalletList } from '../../../utils/axios-helper/api-hander';
import { useDispatch } from 'react-redux';
import useAuth from '../../../utils/hooks/use-auth';

const UniqueCodeModal = (props) => {
  const { openSuccessPopup } = usePopup();
  const { handleSuccess } = useSuccessHandler();
  const dispatch = useDispatch()
  const { userDetails } = useAuth()
  const { t, currentLanguage } = useTranslation();
  const { show, onClose, type, params } = props;
  const { toggleLoader } = useLoader();
  const uniquecodeQuotaFinish = userDetails && userDetails?.uniquecodeQuotaFinish
  console.log(uniquecodeQuotaFinish, "uniquecodeQuotaFinish", userDetails)
  let contentType = '';

  console.log('params', params);

  const initialValues = {
    UniqueCode: '',
  };

  //handle unique code submit api call
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    console.log(values, ':unique code values');
    toggleLoader(true);
    try {
      const langId = getlanguageId(currentLanguage)
      const hfSessionKey = await getCDSPixelId();

      const reqData = {
        mixcode: values.UniqueCode,
        hfSession: hfSessionKey || '',
        languageId: getlanguageId(currentLanguage),
      };
      const res = await mixCodeApi(reqData);
      console.log(res,"resresres mix")
      res && getUserDetails(userDetails, dispatch, toggleLoader)

      if(res.data.statusCode===203)
        {
          contentType = getModelType(t, 12);
          openSuccessPopup(true, contentType);
          return;
        }
      if (res.data && res.data.succeeded) {
        //ga events for successfull unique code submission
        getWalletList(toggleLoader, dispatch)
        if (isTamil()) {
          window.dataLayer.push({ event: 'CSTamil24_UniqueCode_Submit' });
        } else {
          window.dataLayer.push({ event: 'CSBharat24_UniqueCode_Submit' });
        }
        resetForm();
        type === 'model' && onClose();
        let resData = res.data && res.data.data;
        let claimableRewardId = resData?.rewardId !== 1 && resData?.canClaim === true ? resData?.rewardId : null;
        // console.log(claimableRewardId,"claimableRewardId unique code")
        // claimableRewardId && handleCLaimWindow(claimableRewardId, langId)
        // handleSuccess(resData, 'unique_code');
        // if (claimableRewardId) {
        //   const id = resData?.rewardId
        //   isClaimFormWindowOpen(id, langId).then(result => {
        //     handleSuccess(resData, 'unique_code', result);
        //   });
        // } else {
          console.log(resData,"resssdata")
          handleSuccess(resData, 'unique_code');
        // }
      } else {
        let message = res.data && res.data.message;
        //setFieldError('UniqueCode',message)
        console.log('statuscode', res.data && res.data.statusCode);
        if (res.data && res.data.statusCode === 203) {
          type === 'model' && onClose();
          resetForm();
          contentType = getModelType(t, 4, '', message);
          // openSuccessPopup(true, contentType);
        } else if (res.data && res.data.statusCode !== 200) {
          !uniquecodeQuotaFinish && setFieldError('UniqueCode', message);
          uniquecodeQuotaFinish && resetForm();
        }
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  const renderInputs = [
    {
      type: 'text',
      name: 'UniqueCode',
      placeHolder: 'xxxxxxxxxx',
    },
  ];

  let uniqueCodeValidation = uniqueCodeValidationSchema(t);
  console.log('$$$$show', show);
  console.log(uniquecodeQuotaFinish, "uniquecodeQuotaFinish")
  return (
    <>
      {type === 'model' && show ? (
        <AppModal
          show={show}
          size="md"
          onClose={onClose}
          bodyClass="modal-response modal-unique-code text-center">
          <img
            style={{ maxHeight: '250px' }}
            src="/assets/img/modal/unique-code_bottle.gif"
            alt="unique code"
            className="modal-icon mb-md-4 mb-2"
          />
          <h5 className="modal-heading pb-3 pb-md-5 text-center">
            {params && params.enterNow
              ? t.contest.enter_unique_code_to_unlock
              : t.contest.enter_unique_code}
          </h5>
          <div className="row justify-content-center">
            <div className="col-sm-8">
              <CommonFormikForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                formType="enter_unique_code"
                renderInputs={renderInputs}
                validationSchema={uniqueCodeValidation}
              />
            </div>
          </div>
        </AppModal>
      ) : type === 'input' ? (
        <Formik
          initialValues={initialValues}
          validationSchema={uniqueCodeValidation}
          onSubmit={handleSubmit}>
          {({ handleSubmit, values, touched, errors }) => (
            <Form
              className="w-100 position-relative"
              autoComplete="off"
              onSubmit={handleSubmit}>
              {/* Input Field */}
              <Field name="inputField">
                {({ field }) => (
                  <div className="unique-code-wrapper">
                    <input
                      placeholder={t.contest.enter_unique_code}
                      {...field}
                      type="text"
                      name={'UniqueCode'}
                      value={!uniquecodeQuotaFinish ? values.UniqueCode : ""}
                      onInput={(e) => {
                        handleInputLimitedLength(e, 10, 10);
                      }}
                      disabled={uniquecodeQuotaFinish}
                      style={{ opacity: uniquecodeQuotaFinish ? 0.6 : 1 }}
                    />

                    <button
                      onClick={() =>
                        CounterFloodLight([
                          'DC-12665261/coca-0/cocac01q+unique',
                          'DC-12665261/coca-0/cocac02j+unique',
                        ])
                      }
                      className={`btn btn-sm btn-danger unicodesubmitbd`}
                      type="submit"
                      disabled={uniquecodeQuotaFinish}
                    >
                      {t.common.submit}
                    </button>
                  </div>
                )}
              </Field>
              {
                !uniquecodeQuotaFinish &&
              <ErrorMessage
                name={'UniqueCode'}
                component="div"
                className={
                  touched['UniqueCode'] && errors['UniqueCode']
                    ? 'text-danger mt-1'
                    : 'text-danger'
                }
              />
              }
            </Form>
          )}
        </Formik>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default UniqueCodeModal;
