import { version } from "../config";
import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";

//get p
export const getProfile = async (data) => {
  return await AxiosInstance.get(
    `${API_END_POINT.getUserProfile}?userId=${data.userId}`
  );
};

export const updateUserProfile = async (data) => {
  return await AxiosInstance.post(API_END_POINT.updateProfile, data);
};

export const sendReferralAPI = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.sendReferral}`,
    data
  );
};

export const sendAgainReferralAPI = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.sendAgainReferral}`,
    data
  );
};

export const getAllreferralAPI = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.getReferral}?PageNumber=${data.PageNumber}&Pagesize=${data.Pagesize}`
  );
};
export const getAllWinnerAPI = async (data) => {
  return await AxiosInstance.get(
    `${API_END_POINT.getAllWinner}?PageNo=${data.PageNumber}&PageSize=${data.Pagesize}`
  );
};

export const getPendingreferralAPI = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.pendingReferalList}?PageNumber=${data.PageNumber}&Pagesize=${data.Pagesize}`
  );
};

export const verifyRefCodeAPI = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.verifyRefCode}`,
    data
  );
};

export const sendReminderAPI = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.sendReminder}`,
    data
  );
};

export const getProfileQDetails = async (req) => {
  return await AxiosInstance.post(`${API_END_POINT.getAllQuestion}`);
};

export const addEditUserQuestion = async (req) => {
  let query = `?QuestionMasterid=${req.questionId}&OptionId=${req.optionId}&languageId=${req.langId}`
  return await AxiosInstance.post(`${API_END_POINT.addEditUserAnswer}${query}`);
};

export const postProfileQAnswerAPI = async (data) => {
  return await AxiosInstance.post(
    `${API_END_POINT.updateAddEditUserQuestion}`,
    data
  );
};
