import React from 'react';
import AppModal from './index';
import TimerComponent from '../common/Timer';
import useTranslation from "../../utils/hooks/use-translation";
import DOMPurify from "dompurify";
// import { RWebShare } from 'react-web-share';
import { useDispatch } from "react-redux";
import { setCoinAnimation } from "../../store/slices/common-slice";
import { handleShare } from "../../utils/helpers";


const ErrorModel = ({
  share = false,
  shareContent,
  extraContent,
  show,
  onClose,
  image,
  heading,
  subHeading,
  cta,
  timer,
  description,
  timerContent,
  onAction,
  timerValue,
  code,
  bodyClassName = '',
  imageClassName = 'modal-icon mb-md-4 mb-2',
  disabled=false
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch()

  const renderShareButton = () => {
    return (
      // <RWebShare
      //     data={{
      //         text: 'Music has always been a way to uplift oneself, express emotions, and feel connected to others.',
      //         url: shareContent.image ? shareContent.image : '',
      //         title: 'Coke Studio',
      //     }}
      //     onClick={() => { }}>
      //     <button className='btn btn-dark mb-2'>{cta}</button>
      // </RWebShare>
      <button className='btn btn-dark mb-2'
        onClick={() => {
          handleShare({
            text: 'Music has always been a way to uplift oneself, express emotions, and feel connected to others.',
            //url: shareContent.image ? shareContent.image : '',
            title: 'Coke Studio',
            image: shareContent.image ? shareContent.image : '',
          })
        }}

      >{cta}</button>
    )

  }

  return (
    <AppModal
      show={show}
      onClose={onClose}
      bodyClass=" modal-response"
      size="md">
      <div className="text-center">
        {image && <img src={image} alt="Icon" className={`${imageClassName}`} />}
        {heading && <h6 className='modal-heading pb-md-3 pb-1'>{heading}</h6>}
        {subHeading &&
          <p className='modal-description pb-md-2 pb-1'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(subHeading),
            }}
          ></p>}
        {extraContent}
        {code && <h6 className='modal-description pb-md-2 pb-1' style={{ color: 'red' }}>{code}</h6>}
        {timer && <p className='modal-notification pb-md-2 pb-1'><TimerComponent title={t.common.claim_within} initialTime={timerValue} /></p>}
        {timerContent && <p className='modal-notification pb-md-2 pb-1'>{timerContent}</p>}
        {cta && !share && <button className='btn btn-dark mb-2' disabled={disabled} onClick={() => {
          onAction()
          setTimeout(() => {
            dispatch(setCoinAnimation(false))
          }, 2500);
        }}>{cta}</button>}
        {cta && share && renderShareButton()}
        {description && <p className='modal-notification'>{description}</p>}
      </div>
    </AppModal>
  );
}
export default ErrorModel
