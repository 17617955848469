import React from "react";
import { Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ProfileImageSelector from "../components/common/profile/profile-image-selector";
// import ProfileImageSelector from '../components/common/profile/profile-selector';
import OtpInput from "react-otp-input";
import ResendOTPTimer from "./helpers/Timer";
import AddressForm from "../components/common/appForm/AddressForm";
import useTranslation from "./hooks/use-translation";
import {
  handleInputLimitedLength,
  handleKeyPressMobileNumber,
  handleKeyPressName,
  CounterFloodLight,
  MetaPixel,
} from "./helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { DatePicker } from '@y0c/react-datepicker';
// import '@y0c/react-datepicker/assets/styles/calendar.scss';

const CommonFormikForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  renderInputs,
  profileState,
  formClass,
  submitBtnClass = "",
  formFooterClass = "",
  ButtonText = "",
  renderSeparator,
  inputStyle,
  isDisplayEditIcon = true,
  gaClass,
  formType = "",
  redirectTo,
  errorClass,
}) => {
  const { t } = useTranslation();
  console.log(renderInputs, "renderInputs");
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldValue,
        touched,
        getFieldProps,
        setFieldError,
      }) => (
        <Form autoComplete="off" className={formClass}>
          {renderInputs.map((inputProps, index) => (
            <div
              key={index}
              className={`form-group ${inputProps.formGroupClass || ""} ${
                inputProps.type === "image"
                  ? "position-relative text-center "
                  : ""
              }`}
            >
              {inputProps.label && (
                <label htmlFor={inputProps.name}>{inputProps.label}</label>
              )}
              {inputProps.type === "text" && (
                <Field
                  type={inputProps.type}
                  name={inputProps.name}
                  className={`form-control ${
                    touched[inputProps.name] && errors[inputProps.name]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeHolder={inputProps.placeHolder}
                  disabled={inputProps.disabled ? true : false}
                  onInput={(e) => {
                    if (inputProps.name === "mobile") {
                      handleKeyPressMobileNumber(e);
                    }
                    if (inputProps.name === "UniqueCode") {
                      handleInputLimitedLength(e, 10, 10);
                    }
                    if (inputProps.name === "code") {
                      handleInputLimitedLength(e, 6, 6);
                    }
                    if (inputProps.name === "otp") {
                      handleInputLimitedLength(e, 6, 6);
                    }
                    if (inputProps.name === "name") {
                      handleKeyPressName(e);
                    }
                  }}
                  //onBlur={() => inputProps.action(values[inputProps.name], setFieldError)}
                />
              )}
              {inputProps.type === "email" && (
                <Field
                  type={inputProps.type}
                  name={inputProps.name}
                  className={`form-control ${
                    touched[inputProps.name] && errors[inputProps.name]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeHolder={inputProps.placeHolder}
                  disabled={inputProps.disabled ? true : false}
                />
              )}
              {inputProps.type === "password" && (
                <Field
                  type="password"
                  name={inputProps.name}
                  className={`form-control ${
                    touched[inputProps.name] && errors[inputProps.name]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeHolder={inputProps.placeHolder}
                  disabled={inputProps.disabled ? true : false}
                />
              )}
              {console.log("values[inputProps.name]", values[inputProps.name])}
              {inputProps.type === "date" && (
                <>
                  {/* <Field
                    type={inputProps.type}
                    name={inputProps.name}
                    placeholder="Select a date"
                    className={`form-control w-100 form-date ${
                      touched[inputProps.name] && errors[inputProps.name]
                        ? 'is-invalid'
                        : ''
                    }`}
                    placeHolder={inputProps.placeHolder}
                    disabled={inputProps.disabled ? true : false}
                    value={values[inputProps.name]}
                  /> */}

                  <Field
                    name={inputProps.name}
                    disabled={inputProps.disabled ? true : false}
                  >
                    {({ field, form }) => (
                      <DatePicker
                        id="date"
                        {...field}
                        selected={field.value}
                        wrapperClassName={`d-block custom-date-picker`}
                        className={`form-control w-100 form-date ${
                          touched[inputProps.name] && errors[inputProps.name]
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholderText={inputProps.placeHolder}
                        onChange={(date) =>
                          form.setFieldValue(field.name, date)
                        }
                        //dateFormat="MM/DD/YYYY"
                        maxDate={new Date()}
                        icon={"/assets/img/icons/calender-icon.svg"}
                        dropdownMode="select"
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        onChangeRaw={(e) => e.preventDefault()}
                        isClearable
                      />
                    )}
                  </Field>
                </>
              )}
              {inputProps.type === "select" && (
                <Field
                  as="select"
                  name={inputProps.name}
                  className={`form-select form-custom-select ${
                    touched[inputProps.name] && errors[inputProps.name]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeHolder={inputProps.placeHolder}
                  value={values[inputProps.name]}
                  disabled={inputProps.disabled ? true : false}
                >
                  <option value="">{inputProps.placeHolder}</option>
                  {inputProps.options.map((el, optionIndex) => (
                    <option key={optionIndex} value={el.value}>
                      {el.label}
                    </option>
                  ))}
                </Field>
              )}
              {inputProps.type === "image" && (
                <ProfileImageSelector
                  selected={values?.profileImage}
                  setFieldValue={setFieldValue}
                  error={values?.profileImage && profileState?.error?.message}
                  values={values}
                  isDisplayEditIcon={isDisplayEditIcon}
                  getFieldProps={getFieldProps}
                  // dropPosition="down-centered"
                />
              )}

              {inputProps.type === "address" && (
                <AddressForm
                  selected={values?.profileImage}
                  setFieldValue={setFieldValue}
                  getFieldProps={getFieldProps}
                  source={"profile"}
                />
              )}

              {inputProps.type === "checkbox" && (
                <>
                  <div className="d-flex">
                    <Field
                      id="tnc"
                      type="checkbox"
                      name={inputProps.name}
                      className="form-group form-check mr-2"
                      placeHolder={inputProps.placeHolder}
                      onChange={(e) => {
                        setFieldValue(inputProps.name, e.target.checked);
                        if (inputProps.isAction && e.target.checked) {
                          inputProps.onAction();
                        }
                      }}
                    />
                    <label
                      className="small"
                      htmlFor="tnc"
                      dangerouslySetInnerHTML={{
                        __html: inputProps.heading,
                      }}
                    ></label>
                  </div>
                </>
              )}
              {inputProps.type === "react-otp-input" && (
                <>
                  <OtpInput
                    value={values.otp}
                    onChange={(e) => setFieldValue(inputProps.name, e)}
                    numInputs={6}
                    containerStyle="justify-content-center"
                    renderSeparator={renderSeparator}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className={`form-control form-square ${
                          touched[inputProps.name] && errors[inputProps.name]
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                    )}
                    inputType="tel"
                  />
                </>
              )}

              <ErrorMessage
                name={inputProps.name}
                component="div"
                className={
                  touched[inputProps.name] && errors[inputProps.name]
                    ? `text-danger ${errorClass}`
                    : `text-danger ${errorClass}`
                }
              />
              {inputProps.type === "react-otp-input" ||
                (inputProps?.name === "otp" && (
                  <div className="text-center mt-5">
                    <ResendOTPTimer
                      onSuccess={() => setFieldValue("otp", "")}
                      gaClass={inputProps.gaClass}
                    />
                  </div>
                ))}
            </div>
          ))}
          {errors.Message && (
            <div className={"text-danger"}>{errors.Message}</div>
          )}

          <div className={`form-footer ${formFooterClass}`}>
            <Button
              type="submit"
              variant="dark"
              className={`btn-block ${submitBtnClass} ${gaClass}`}
              id={gaClass}
              onClick={() => {
                if (formType && formType === "refer_Now") {
                  CounterFloodLight([
                    "DC-12665261/coca-0/cocac02c+unique",
                    "DC-12665261/coca-0/cocac036+unique",
                  ]);
                }
                if (formType && formType === "save_details") {
                  CounterFloodLight([
                    "DC-12665261/coca-0/cocac02d+unique",
                    "DC-12665261/coca-0/cocac037+unique",
                  ]);
                }
                if (formType && formType === "otpValidation") {
                  CounterFloodLight([
                    "DC-12665261/coca-0/cocac029+unique",
                    "DC-12665261/coca-0/cocac032+unique",
                  ]);
                  MetaPixel("CSBOTPSubmit", "CSTOTPSubmit");
                }
                if (formType && formType === "get_otp") {
                  CounterFloodLight([
                    "DC-12665261/coca-0/cocac01_+unique",
                    "DC-12665261/coca-0/cocac02t+unique",
                  ]);
                  MetaPixel("CSBGetOTP", "CSTGetOTP");
                }
                if (formType && formType === "enter_unique_code") {
                  CounterFloodLight([
                    "DC-12665261/coca-0/cocac01q+unique",
                    "DC-12665261/coca-0/cocac02j+unique",
                  ]);
                  MetaPixel("CSBUniqueCode", "CSTUniqueCode");
                }
              }}
            >
              {ButtonText ? ButtonText : t.common.submit}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CommonFormikForm;
