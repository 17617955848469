/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Image,
  Dropdown,
} from "react-bootstrap";
import Sidebar from "../sidebar";
import LanguageSwitcher from "../common/languageSwitcher";
import useAuth from "../../utils/hooks/use-auth";
import UniqueCode from "../common/appForm/UniqueCode";
// import Notifications from '../../pages/Notification';
import { logoutUser } from "../../store/slices/auth-slice";
import { useDispatch } from "react-redux";
import { useViewport } from "../../utils/context/viewport-context";
// import { getAllHomepageDetailsALogin } from "../../utils/axios-helper/api-hander";
import { useSelector } from "react-redux";
// import useLoader from "../../utils/hooks/use-loader";
import { menuData } from "../../utils/constants";
import useTranslation from "../../utils/hooks/use-translation";
import {
  addPathSeparators,
  CounterFloodLight,
  isBharatCampaignEnded,
  isTamilCampaignEnded,
  manageKaraokePath,
  MetaPixel,
} from "../../utils/helpers";
import gluedin from "gluedin";
import { setAfterLoginAction } from "../../store/slices/common-slice";
import { useLocation } from "react-router-dom";
import usePopup from "../../utils/hooks/use-popup";
import { isTamil } from "../../utils/helpers";
import { gluedinLogin } from "../../services";
import { getAllHomepageDetailsALogin } from "../../utils/axios-helper/api-hander";
import useLoader from "../../utils/hooks/use-loader";

const Header = () => {
  const { pathname } = useLocation();
  const { t, changeLanguage } = useTranslation();
  const {toggleLoader} = useLoader()
  let allMenuOptions = menuData(t);
  const { isMobile } = useViewport();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { toggleLoader } = useLoader();
  const { isLoggedIn, userDetails } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef();
  const { openReferFriendPopup } = usePopup();
  const homePageData = useSelector((state) => state?.common?.homepageDetails);
  const siteType = isTamil() ? "tamil" : "bharat";
  const notificationCount = homePageData && homePageData.notificationCount;
  console.log("homePageData", notificationCount);
  var isCampaignOver = siteType === "tamil"? isTamilCampaignEnded(): isBharatCampaignEnded();
  let exploreOption =
    allMenuOptions && allMenuOptions.find((el) => el.id === 3);

  useEffect(() => {
    if (!isMobile) {
      setIsExpanded(false);
    }
    // Add event listener to handle clicks outside the navbar
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobile]);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsExpanded(false);
      document.body.style.overflow = "auto";
    }
  };
  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      document.body.style.overflow = "hidden"; // Disable scrolling when menu is open
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling when menu is closed
    }
  };

  const handleClick = (el) => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    // Redirect to the home page
    if (!isLoggedIn) {
      navigate("/");
    }
  };

  const logoutUserAction = () => {
    localStorage.clear();
    dispatch(logoutUser());
    localStorage.setItem("siteType", siteType);
    window.location.assign(`/${siteType}`);
    dispatch(
      setAfterLoginAction({
        action: "",
        redirectPath: "",
      })
    );
  };
  // const rederLogoutModel = (data) => {
  //   let contentType = {
  //     image: "/assets/img/modal/daily-limit.svg",
  //     heading: "Leaving so soon?",
  //     subHeading:
  //       "Log in and engage with the platform to win exciting rewards.",
  //     description: "Are you sure you want to exit?",
  //     confirm: "Yes",
  //     cancel: "No",
  //     onAction: logoutUserAction,
  //   };
  //   openLogoutPopup(true, contentType);
  // };

  const navigateTo = (route) => {
    console.log("route", route);
    // Close the sidebar
    setIsExpanded(false);
    // Navigate to the specified route
    // navigate(route);
  };

  // const handlePopoverToggle = (isOpen) => {
  //   // isOpen will be true if the popover is open, false if closed
  //   if (isOpen) {
  //     getAllHomepageDetailsALogin(dispatch, toggleLoader);
  //   }
  // };

  const gluedinLogout = () => {
    sessionStorage.removeItem("guestToken");
    sessionStorage.removeItem("community-closed");
    sessionStorage.removeItem("uniqueToken");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("hdkjfsdhfkhdskfhkdshfkdsgcvbjhsdbvdfkjh");
  };

  const handleGluedinLogin = () => {
    let formData = {
      email: userDetails && `${userDetails.id}@gmail.com`,
      password: userDetails && userDetails.id,
    };
    gluedinLogin(formData);
  };

  const handleTamilRedirection = () => {
    changeLanguage("en");
    // dispatch(logoutUser());
    // localStorage.clear();
    gluedinLogout();
    window.location.reload();
    localStorage.setItem("siteType", "tamil");
    let gluedInSDKInitilize = new gluedin.GluedInSDKInitilize();
    gluedInSDKInitilize.initialize({
      apiKey: process.env.REACT_APP_API_KEY_TAMIL,
      secretKey: process.env.REACT_APP_SECRET_KEY_TAMIL,
    });
    handleGluedinLogin();
  };

  const handleBharatRedirection = () => {
    changeLanguage("en");
    // dispatch(logoutUser());
    // localStorage.clear();
    gluedinLogout();
    window.location.reload();
    localStorage.setItem("siteType", "bharat");
    let gluedInSDKInitilize = new gluedin.GluedInSDKInitilize();
    gluedInSDKInitilize.initialize({
      apiKey: process.env.REACT_APP_API_KEY_BHARAT,
      secretKey: process.env.REACT_APP_SECRET_KEY_BHARAT,
    });
    handleGluedinLogin();
  };

  return (
    <>
      {!pathname.includes("karaoke_recording/Video") && (
        <header className="header">
          <Navbar
            expand="lg"
            variant={"null"}
            bg={"light"}
            expanded={isExpanded}
            ref={navbarRef}
          >
            <Container fluid>
              <Navbar.Toggle aria-controls="sidebar" onClick={toggleNavbar} />
              <h1 title={isTamil() ? t.common.h1TTitle : t.common.h1BTitle}>
                <Navbar.Brand
                  href={addPathSeparators("/")}
                  onClick={handleClick}
                >
                  <img
                    className="logo"
                    src={`/assets/img/icons/${siteType}-logo.svg`}
                    alt="Coca-Cola"
                  />
                </Navbar.Brand>
              </h1>
              {/* Only  for Mobile  */}
              <Nav className="align-items-center navbar-right d-lg-none">
                {isLoggedIn && (
                <div className="dropdown">
                  <a
                    href={void 0}
                    className="icon-notifi icon-Notification-01 view_notification"
                    onClick={() => {
                      getAllHomepageDetailsALogin(dispatch, toggleLoader);
                      navigate(addPathSeparators('/notifications'));
                    }}>
                    {notificationCount > 0 && (
                      <span className="notification-count">
                        {notificationCount}
                      </span>
                    )}
                  </a>
                </div>
              )}
                <LanguageSwitcher />
              </Nav>
              {/* Sidebar menu for mobile  */}
              <Navbar.Collapse
                id="sidebar"
                className="offcanvas-menu d-lg-none sidebar"
              >
                <Sidebar navigateTo={() => navigateTo()} />
              </Navbar.Collapse>
              {isExpanded && (
                <div
                  className="offcanvas-backdrop"
                  onClick={toggleNavbar}
                ></div>
              )}
              {/* Only  for Desktop  */}
              <Navbar.Collapse className="d-none d-lg-block">
                <Nav className="me-auto navbar-left" as="ul">
                  <NavDropdown title={t.header.explore} as="li">
                    {exploreOption &&
                      exploreOption.subMenu.map((el) => {
                        return (
                          <NavDropdown.Item
                            href=//     ? `${siteType}/login` //   el.id === 13 && !isLoggedIn // {
                            //     : el.url
                            // }
                            {
                              (el.id === 13 && !isLoggedIn) ||
                              (el.id === 10 && !isLoggedIn)
                                ? `${siteType}/login`
                                : el.url
                            }
                            onClick={handleClick}
                          >
                            <span
                              dangerouslySetInnerHTML={{ __html: el.title }}
                              onClick={() => {
                                handleClick(el);
                                if (el.id === 10) {
                                  if (isLoggedIn) {
                                    dispatch(
                                      setAfterLoginAction({
                                        action: "",
                                        redirectPath: manageKaraokePath(),
                                      })
                                    );
                                    CounterFloodLight([
                                      "DC-12665261/coca-0/cocac021+unique",
                                      "DC-12665261/coca-0/cocac02w+unique",
                                    ]);
                                  }
                                }
                                if (el.id === 6) {
                                  handleTamilRedirection();
                                } else if (el.id === 7) {
                                  handleBharatRedirection();
                                } else if (el.id === 13) {
                                  console.log("item.id", el.id);
                                  if (isLoggedIn) {
                                    openReferFriendPopup();
                                  } else {
                                    dispatch(
                                      setAfterLoginAction({
                                        action: openReferFriendPopup,
                                        redirectPath: "",
                                      })
                                    );
                                  }
                                }
                              }}
                            />
                          </NavDropdown.Item>
                        );
                      })}
                  </NavDropdown>
                  <Nav.Item as="li">
                    <Nav.Link
                      href={addPathSeparators("/contest")}
                      onClick={() => {
                        CounterFloodLight([
                          "DC-12665261/coca-0/cocac01t+unique",
                          "DC-12665261/coca-0/cocac02m+unique",
                        ]);
                      }}
                    >
                      {t.header.participate_and_win}
                    </Nav.Link>
                  </Nav.Item>
                  {isCampaignOver ? (
                    <Nav.Item as="li">
                      <Nav.Link
                        href={addPathSeparators("/info")
                        }
                        // onClick={() => {
                        //   dispatch(
                        //     setAfterLoginAction({
                        //       action: "",
                        //       redirectPath: "/info",
                        //     })
                        //   );
                        // }}
                      >
                        {t.common.info}

                        <img
                          style={{ width: "18px", height: "18px" }}
                          src="/assets/img/footer/info-icon2.svg"
                          alt=" info"
                          className="footer-icon ml-2"
                        />
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    ""
                  )}
                </Nav>
                <Nav className="ml-auto align-items-center navbar-right">
                  {isLoggedIn && <UniqueCode type={"input"} />}
                  <LanguageSwitcher />
                  {/* {isLoggedIn && (
                  <Dropdown onToggle={handlePopoverToggle}>
                    <Dropdown.Toggle
                      id="dropdown-notification"
                      as="a"
                      className="icon-notifi icon-Notification-01">
                      {notificationCount > 0 && (
                        <span className="notification-count">
                          {notificationCount}
                        </span>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu align={'end'} as="div">
                      <Notifications popover={true} />
                    </Dropdown.Menu>
                  </Dropdown>
                )} */}

                  {isLoggedIn ? (
                    <>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-user"
                          as="a"
                          className="header-profile-pic avatar_icon"
                        >
                          <Image
                            src={
                              userDetails && userDetails.avatarURL
                                ? userDetails.avatarURL
                                : "/assets/img/icons/default-user.svg"
                            }
                            alt=""
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={"end"} as="div">
                          <Dropdown.Item
                            href={addPathSeparators("/my_profile")}
                          >
                            <span>{t.header.my_profile}</span>
                            <span className="icon icon-My-Profile-01"></span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              CounterFloodLight([
                                "DC-12665261/coca-0/cocac028+unique",
                                "DC-12665261/coca-0/cocac031+unique",
                              ]);
                            }}
                            href={addPathSeparators("/my_wallet")}
                          >
                            <span>{t.header.my_wallet}</span>
                            <span className="icon icon-My-Wallet-01"></span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            onClick={() => {
                              logoutUserAction();
                              CounterFloodLight([
                                "DC-12665261/coca-0/cocac026+unique",
                                "DC-12665261/coca-0/cocac03+unique",
                              ]);
                              MetaPixel("CSBLogout", "CSTLogout");
                            }}
                          >
                            {t.header.logout}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-user"
                        as="a"
                        className="header-profile-pic avatar_icon"
                      >
                        <Image
                          src={
                            userDetails && userDetails.avatarURL
                              ? userDetails.avatarURL
                              : "/assets/img/icons/default-user.svg"
                          }
                          alt=""
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu align={"end"} as="div">
                        <Dropdown.Item
                          href="#"
                          onClick={() => {
                            localStorage.setItem("pathname", pathname);
                            navigate(addPathSeparators("/login"));
                            CounterFloodLight([
                              "DC-12665261/coca-0/cocac025+unique",
                              "DC-12665261/coca-0/cocac02_+unique",
                            ]);
                            MetaPixel("CSBLogin", "CSTLogin");
                          }}
                        >
                          {t.header.login}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      )}
    </>
  );
};

export default Header;
