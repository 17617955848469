import * as yup from 'yup';
import { REGEX } from '../constants/validation-regex';
import { validateReferCode, getCityStateByPinCode } from '../../services';

//OTP VALIDATION
export const otpValidationSchema = (t) => {
  return yup.object({
    otp: yup
      .string()
      .required(t.validation_messages.required['otp'])
      .test(
        'otp-digit-length',
        () => t.validation_messages.valid['otp'],
        (value) => {
          console.log('value: ', value);
          if (value) {
            if (`${value}`.length !== 6) {
              return false;
            }
          }
          return true;
        }
      ),
  });
};

//sign-up  validations
export const signUpValidationSchema = (t) => {
  return yup.object({
    name: yup
      .string()
      .matches(REGEX.name, t.validation_messages.valid['name'])
      .nullable(),
    email: yup
      .string()
      .email(t.validation_messages.valid['email'])
      .matches(REGEX.email, t.validation_messages.valid['email'])
      .nullable(),
    AgreeTerms: yup
      .bool()
      .oneOf([true], t.validation_messages.required['terms_conditions']),
    referalCode: yup
      .string()
      .test(
        'validateReferalCode',
        t.validation_messages.valid['refer_code'],
        async function (value) {
          if (value) {
            try {
              const res = await validateReferCode(value);
              if (res.data && res.data.succeeded === false) {
                return false; // Invalid referral code
              }
            } catch (err) {
              // Handle error if needed
              return false; // Invalid referral code
            }
          }

          return true; // Valid referral code or empty
        }
      ),
  });
};

//address validation schema

//address validation schema
export const addressValidationSchema = (t) => {
  return yup.object({
    AddressOne: yup
      .string()
      .matches(REGEX.Address, t.validation_messages.valid['address'])
      .required(t.validation_messages.required['adress_line1'])
      .test('no-spaces',t.validation_messages.required['adress_line1'], value => !(/^\s+$/.test(value))),
    AddressTwo: yup
      .string()
      .matches(REGEX.Address, t.validation_messages.valid['address'])
      .test('no-spaces', t.validation_messages.valid['address'], value => !(/^\s+$/.test(value)))
      .nullable(),
    Near_Landmark: yup
      .string()
      .matches(REGEX.Address, t.validation_messages.valid['address'])
      .test('no-spaces', t.validation_messages.valid['address'], value => !(/^\s+$/.test(value)))
      .nullable(),
    State: yup.string().required(t.validation_messages.required['state']),
    City: yup.string().required(t.validation_messages.required['city']),
    Pincode: yup
      .string()
      .test(
        'validateReferalCode',
        t.validation_messages.valid['pin_serviceable'],
        async function (value) {
          if (value) {
            try {
              const res = await getCityStateByPinCode(value);
              if (res.data && res.data.data === null) {
                return false; // Pincode not serviceable
              }
            } catch (err) {
              // Handle error if needed
              return false; // Invalid referral code
            }
          }
          return true; // Valid referral code or empty
        }
      )
      .length(6, t.validation_messages.valid['pincode'])
      .required(t.validation_messages.required['pincode']),
    shippingMobile: yup
      .string()
      .min(10, `${t.validation_messages.valid['mobile']}`)
      .max(10, `${t.validation_messages.valid['mobile']}`)
      .matches(REGEX.mobile, t.validation_messages.valid['mobile'])
      .nullable(),
  });
};


//claim form validation
//claim form validation
export const createValidationSchema = (
  isPassportFieldShow,
  isPanFieldShow,
  t
) => {
  return yup.object({
    Name: yup
      .string()
      .required('Name is required')
      .matches(REGEX.name, t.validation_messages.valid['name'])
      .test('no-spaces',t.validation_messages.valid['name'], value => !(/^\s+$/.test(value))),
    mobile: yup
      .string()
      .min(10, `${t.validation_messages.valid['mobile']}`)
      .max(10, `${t.validation_messages.valid['mobile']}`)
      .matches(REGEX.mobile, t.validation_messages.valid['mobile'])
      .required(t.validation_messages.required['mobile'])
      .test('no-spaces',t.validation_messages.valid['mobile'], value => !(/^\s+$/.test(value))),
    Email: yup
      .string()
      .email(t.validation_messages.valid['email'])
      .matches(REGEX.email, t.validation_messages.valid['email'])
      .required(t.validation_messages.required['email'])
      .test('no-spaces',t.validation_messages.valid['email'], value => !(/^\s+$/.test(value))),
    PassNo: isPassportFieldShow
      ? yup
          .string()
          .required(t.validation_messages.required['passport'])
          .matches(REGEX.Passport, t.validation_messages.valid['passport'])
          .test('no-spaces',t.validation_messages.valid['passport'], value => !(/^\s+$/.test(value)))
      : null,
    Govid: isPanFieldShow
      ? yup
          .string()
          .required(t.validation_messages.required['govid'])
          .matches(REGEX.Govid, t.validation_messages.valid['govid'])
          .test('no-spaces',t.validation_messages.valid['govid'], value => !(/^\s+$/.test(value)))
      : null,
  });
};
//login form validation
export const loginValidationSchema = (t) => {
  return yup.object({
    mobile: yup
      .string()
      .min(10, `${t.validation_messages.valid['mobile']}`)
      .max(10, `${t.validation_messages.valid['mobile']}`)
      .matches(REGEX.mobile, t.validation_messages.valid['mobile'])
      .required(t.validation_messages.required['mobile']),
  });
};

//unique code form validation
export const uniqueCodeValidationSchema = (t) => {
  return yup.object({
    UniqueCode: yup
      .string()
      .matches(REGEX.Code, t.validation_messages.valid['code'])
      .required(t.validation_messages.required['code']),
  });
};

//mobile number validation for refer friend
export const mobileNoValidationSchema = (t) => {
  return yup.object({
    mobile: yup
      .string()
      .matches(REGEX.mobile, t.validation_messages.valid['mobile'])
      .required(t.validation_messages.required['mobile']),
  });
};

//refer code validation
export const referCodeValidationSchema = (t) => {
  return yup.object({
    code: yup.string().required(t.validation_messages.required['refer_code']),
  });
};

//edit profile validation
export const profileCardSchema = (t) => {
  return yup.object({
    name: yup
      .string()
      .nullable() // Allow undefined or empty string values
      .matches(REGEX.name, t.validation_messages.valid['name']),
    mobile: yup
      .string()
      .nullable() // Allow undefined or empty string values
      .min(10, `${t.validation_messages.valid['mobile']}`)
      .max(10, `${t.validation_messages.valid['mobile']}`)
      .matches(REGEX.mobile, t.validation_messages.valid['mobile']),
    email: yup
      .string()
      .nullable() // Allow undefined or empty string values
      .email(t.validation_messages.valid['email'])
      .matches(REGEX.email, t.validation_messages.valid['email']),
    dob: yup
      .date()
      .nullable() // Allow undefined or empty string values
      .test('is-over-18', t.validation_messages.valid['age'], function (value) {
        // Check if the value is provided before comparing dates
        if (!value) {
          return true; // Allow empty or undefined values
        }
        const currentDate = new Date();
        const minBirthDate = new Date(
          currentDate.getFullYear() - 18,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return new Date(value) <= minBirthDate;
      }),
    alternate_mobile: yup
      .string()
      .min(10, `${t.validation_messages.valid['mobile']}`)
      .max(10, `${t.validation_messages.valid['mobile']}`)
      .matches(REGEX.mobile, t.validation_messages.valid['mobile'])
      .nullable(),
  });
};

export const StartquizSchema = (t) => {
  return yup.object().shape({
    selectedOption: yup
      .string()
      .required(t.validation_messages.required['quiz']),
  });
};
