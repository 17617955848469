import axios from 'axios';
// import { apiErrorHandler } from './api-hander';
import { API_BASE_URL } from '../../config';
import { store } from '../../store';
import { logoutUser } from '../../store/slices/auth-slice';
import { addPathSeparators } from '../helpers';

// axios instance
export const AxiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
});

// Add a request interceptor
AxiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    const Token = localStorage.getItem('token');
    console.log('Token', Token);
    if (Token) {
      config.headers.Authorization = `Bearer ${Token}`;
    }

    // Check if the request method is PUT, PATCH, or DELETE
    if (['PUT', 'PATCH', 'DELETE'].includes(config.method.toUpperCase())) {
      console.error(`Request method ${config.method} is not allowed.`);
      return Promise.reject(new Error(`Request method ${config.method} is not allowed.`));
  }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // let siteType = window.location.pathname.includes("/tamil") ? "tamil" : "bharat";
    if (error?.response?.status === 401) {
      console.log('error',error?.response?.status)
      // logout user on 401 error
      store.dispatch(logoutUser());
      localStorage.clear();
     
      // toast.error('You are authorised please login again');
      if (typeof window !== 'undefined') {
        //localStorage.setItem('siteType', siteType)
        window.location.assign(addPathSeparators('/'))
      }
    }
    // apiErrorHandler(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
