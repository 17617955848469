import DOMPurify from "dompurify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup } from "react-bootstrap"; // Import FormCheck from react-bootstrap
import { useDispatch } from "react-redux";
import { getTamilQuizDetails, postQuizAnswerAPI } from "../../../services";
import { getWalletList } from "../../../utils/axios-helper/api-hander";
import { ClickCDP } from "../../../utils/cds/cdsHelper";
import { getCDSPixelId } from "../../../utils/helpers";
import useAuth from "../../../utils/hooks/use-auth";
import useLoader from "../../../utils/hooks/use-loader";
import usePopup from "../../../utils/hooks/use-popup";
import useSuccessHandler from "../../../utils/hooks/use-success-model";
import useTranslation from "../../../utils/hooks/use-translation";
import { StartquizSchema } from "../../../utils/validations";
import AppModal from "../../modals";

const Startquiz = ({ show, onClose, params }) => {
  const { currentLanguage } = useTranslation();
  const { handleQuizErrorModel } = useSuccessHandler();
  const { openSuccessPopup } = usePopup();
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(90);
  const timerRef = useRef(null);
  const { isLoggedIn } = useAuth();
  const { toggleLoader } = useLoader();
  const { t } = useTranslation();
  const [quizData, setQuizData] = useState("");
  let startQuizValidation = StartquizSchema(t);
  const quizQuestion =
    quizData &&
    `Q1. ${
      currentLanguage === "en" ? quizData.question : quizData.questionOtherLang
    }`;
  useEffect(() => {
    timerRef.current = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(timerRef.current);
          return 0;
        }
      });
    }, 1000);

    // Clean up the timer
    return () => clearInterval(timerRef.current);
  }, []);

  // Convert seconds to minutes and seconds
  const remainingSeconds = seconds;

  useEffect(() => {
    isLoggedIn && getTamilquizQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const handleSubmit = async (values) => {
    toggleLoader(true);
    try {
      const hfSessionKey = await getCDSPixelId();
      let reqData = {
        quizAnswerRequest: {
          tamilUserQuizeId: quizData && quizData.tamilUserQuizeId,
          quizAnswerId: values && Number(values.selectedOption),
          rewardId: params && params.rewardsId,
          hfSession: hfSessionKey || "",
        },
      };
      const res = await postQuizAnswerAPI(reqData);
      console.log("res.data", res.data);
      let message = res.data && res.data.message;
      getWalletList(toggleLoader, dispatch);
      if (res.data && res.data.succeeded) {
        window.dataLayer.push({ event: "CSTamil24_Quiz_Submit" });
        let contentType = handleQuizErrorModel(message, true);
        openSuccessPopup(true, contentType);
        ClickCDP("Submit_Quiz");
      } else {
        let contentType = handleQuizErrorModel(message, false);
        openSuccessPopup(true, contentType);
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  const getTamilquizQuestions = async () => {
    toggleLoader(true);
    try {
      const res = await getTamilQuizDetails();
      if (res.status === 200) {
        let quizData = res.data && res.data.data;
        setQuizData(quizData);
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  console.log("quizData", quizData);

  const renderModel = () => {
    return (
      <AppModal
        show={show}
        onClose={onClose}
        bodyClass="px-5 pt-4 pb-5 "
        headerClass={"d-none"}
      >
        <Formik
          initialValues={{ selectedOption: "" }}
          validationSchema={startQuizValidation}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormGroup>
              <span className="quiz-question">
                <strong
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(quizQuestion),
                  }}
                />
              </span>

              <div className="quiz-container">
                {quizData &&
                  quizData.answers &&
                  Array.isArray(quizData.answers) &&
                  quizData.answers.length !== 0 &&
                  quizData.answers.map((option) => (
                    <div key={String(option.id)} className="d-flex">
                      <Field
                        as={Form.Check} // Use Form.Check instead of FormCheck
                        type="radio"
                        label={
                          currentLanguage === "en"
                            ? option.option
                            : option.optionOtherLang
                        }
                        name="selectedOption"
                        value={String(option.id)}
                        id={String(option.id)}
                      />
                      <label className="small" htmlFor={String(option.id)}>
                        {currentLanguage === "en"
                          ? option.option
                          : option.optionOtherLang}
                      </label>
                    </div>
                  ))}
              </div>
              <ErrorMessage
                name="selectedOption"
                component="div"
                className="text-danger"
              />
            </FormGroup>
            <Button className="quiz-button mt-2" type="submit" variant="dark">
              {t.common.submit}
            </Button>
            {remainingSeconds !== 0 && (
              <div className="text-center quiz-countdown mt-2">
                <span className="">
                  Time Remaining:{" "}
                  <span className="text-danger">
                    {remainingSeconds < 10
                      ? `0${remainingSeconds} sec`
                      : `${remainingSeconds} sec`}
                  </span>
                </span>
              </div>
            )}
          </Form>
        </Formik>
      </AppModal>
    );
  };

  return <>{quizData && renderModel()}</>;
};

export default Startquiz;
